import { useApolloClient } from '@apollo/client';
import dateFormat from 'dateformat';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { toast } from 'react-toastify';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, LEAD_SOURCE_TAGGING } from '../../../src/config/constants';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { MARK_AS_LOST } from '../../services/leads.gql';
import { getLeadRemarks, getLoanDetail, getMasterData } from '../../store/action/allAction';
import Loader from '../elements/Loader';
import LeadHistory from '../lead-list/LeadHistory';

const LoanRibbenBox = (props) => {
    const {
        toggleTip,
        toggleTip1,
        toggleTip2,
        toggleTip3,
        toggleTip5,
        tipOpen,
        tipOpen1,
        tipOpen2,
        tipOpen3,
        tipOpen5,
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation('language');
    const client = useApolloClient();
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [MarkAsLost, setMarkAsLost] = useState(false);
    const [reopenModal, setReopenModal] = useState(false);
    const [comment, setComment] = useState('');
    const [remarkText, setRemarkText] = useState('');
    const [subStatus, setSubStatus] = useState({
        sub_status: '',
    });
    const [subStatusList, setSubStatusList] = useState([]);
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    const tipContentRef = useRef(null);
    const [tipOpen4, toggleTip4] = useState(0);
    const [tipOpen6, toggleTip6] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(getMasterData(client));
    }, []);
    const { leadDetail, leadHistory } = useSelector(({ lead }) => {
        return {
            leadDetail: lead.leadDetail,
            leadHistory: lead.leadHistory?.get_lead_history,
        };
    });

    const { masterdata } = useSelector(({ masterdata }) => ({
        masterdata: masterdata?.data,
    }));

    let isLeadAssignedLost = IS_USER_ACCESS_PAGE('loanapplication', 'mark_as_lost').is_super_admin
        ? false
        : !(leadDetail?.lead_assigned_to && leadDetail?.lead_assigned_to === UserInfo?.id);
    let isLeadAssignedReopen = IS_USER_ACCESS_PAGE('loanapplication', 're_open').is_super_admin
        ? false
        : !(leadDetail?.lead_assigned_to && leadDetail?.lead_assigned_to === UserInfo?.id);
    let accessConditionLost = !IS_USER_ACCESS_PAGE('loanapplication', 'mark_as_lost').is_edit_access;
    let accessConditionReopen = !IS_USER_ACCESS_PAGE('loanapplication', 're_open').is_edit_access;
    if (!accessConditionLost) {
        accessConditionLost = CHECK_ASSIGN_USER(leadDetail?.lead_assigned_to);
    }
    if (!accessConditionReopen) {
        accessConditionReopen = CHECK_ASSIGN_USER(leadDetail?.lead_assigned_to);
    }
    useEffect(() => {
        // if (lead_id) {
        //     getRemarksHistory(lead_id);
        // }
        if (masterdata) {
            let sub_status_list = masterdata?.sub_status_list?.filter(sub_status => sub_status.status_id === 7)?.map((status) => {
                return { ...status, label: status.sub_status_name, value: status.id };
            });
            setSubStatusList(sub_status_list);
        }
    }, [masterdata]);

    const handleChangeOption = (e) => {
        setSubStatus((prev) => ({
            ...prev,
            sub_status: e.value,
        }));
    };

    const showModalViewTimeLine = (lead_id) => {
        props.getLeadHistory(leadDetail.id);
        setViewTimeLine(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const showModalMarkAsLost = () => {
        setMarkAsLost(true);
        document.body.classList.add('overflow-hidden');
    };

    const showModalReopen = () => {
        setReopenModal(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalMarkAsLost = () => {
        setSubStatus((prev) => ({
            ...prev,
            sub_status: '',
        }));
        setMarkAsLost(false);
        setComment('');
        document.body.classList.remove('overflow-hidden');
    };

    const hideModalReopen = () => {
        setRemarkText('');
        setReopenModal(false);
        document.body.classList.remove('overflow-hidden');
    };

    const submitMarkAsLost = (e) => {
        if (!subStatus.sub_status) {
            toast.error('Please select Sub Status first');
        } else if (subStatus.sub_status === 4 && /^\s*$/.test(comment)) {
            toast.error('Comment cannot be empty');
        } else {
            setIsLoading(true);
            executeGraphQLMutation(
                MARK_AS_LOST,
                {
                    action_type: 'lost',
                    lead_id: leadDetail.id,
                    status_id: 7,
                    sub_status_id: subStatus.sub_status,
                    comment: comment,
                    user_id: UserInfo?.id || 1,
                },
                client,
            )
                .then(async (res) => {
                    if (res.data.markAsLost.message === 'success') {
                        toast.success('Success');
                        hideModalMarkAsLost();
                        await props.getLeadHistory(leadDetail.id);
                        await dispatch(getLoanDetail(leadDetail.id, client));
                        dispatch(getLeadRemarks({ lead_id: leadDetail.id, source: 'WEB' }, client));
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        toast.error('Error');
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(error.toString().replace('ApolloError:', ''));
                });
        }
    };

    const submitReopen = () => {
        if (!remarkText || /^\s*$/.test(remarkText)) {
            toast.error('Please enter remarks');
        } else {
            setIsLoading(true);
            executeGraphQLMutation(
                MARK_AS_LOST,
                {
                    action_type: 'reopen',
                    lead_id: leadDetail.id,
                    sub_status_id: 0,
                    comment: remarkText,
                    user_id: UserInfo?.id || 1,
                },
                client,
            )
                .then(async (res) => {
                    if (res.data.markAsLost.message === 'success') {
                        toast.success('Success');
                        hideModalReopen();
                        await props.getLeadHistory(leadDetail.id);
                        await dispatch(getLoanDetail(leadDetail.id, client));
                        dispatch(getLeadRemarks({ lead_id: leadDetail.id, source: 'WEB' }, client));
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        toast.error(res?.data?.markAsLost?.message || 'Error');
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(error.toString().replace('ApolloError:', ''));
                });
        }
    };

    if (leadDetail) {
        return (
            <div className="loan-bx-ribben">
                {isLoading ? <Loader /> : null}
                <ul className="maindetail-list clearfix ">
                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Application Id"}>Application Id</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title="">{leadDetail?.lead_code}</li>
                            </ul>
                        </div>
                    </li>
                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Submitted by"}>Submitted by</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title="">
                                    <span className="truncate" title={leadDetail.user_details?.name}>
                                        {leadDetail.user_details?.name}
                                    </span>
                                    <span
                                        onClick={() => {
                                            toggleTip2(true);
                                            toggleTip(false);
                                            toggleTip1(false);
                                            toggleTip3(false);
                                            toggleTip4(false);
                                            toggleTip5(false);
                                        }}
                                        title=""
                                        className="tooltio-ic"
                                    >
                                        <i className="ic-info-icon1"></i>
                                    </span>
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip2(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>SO ID</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 6,
                                                                )?.id ||
                                                                    leadDetail.user_details?.id ||
                                                                    ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>SO Name</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 6,
                                                                )?.name ||
                                                                    leadDetail.user_details?.name ||
                                                                    ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>SM ID</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 5,
                                                                )?.id || ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>SM Name</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 5,
                                                                )?.name || ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>TH ID</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 4,
                                                                )?.id || ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>TH Name</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 4,
                                                                )?.name || ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>NSH ID</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 3,
                                                                )?.id || ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>NSH Name</span>
                                                            <label>
                                                                {leadDetail?.user_details?.managers?.find(
                                                                    (manager) => manager.role_id === 3,
                                                                )?.name || ''}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen2}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Created Date"}>Created Date</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title="Created Date">{dateFormat(leadDetail.created_date, 'dd mmm, yyyy')}</li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Customer Details"}>Customer Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="buyer-txt" title={leadDetail?.customer?.name}>
                                        {leadDetail?.customer?.name}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Vehicle Details"}>Vehicle Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span
                                        className="truncate"
                                        title={`${leadDetail.lead_vehicle_detail?.make_name || leadDetail.lead_vehicle_detail?.other_make_name || ''} ${leadDetail.lead_vehicle_detail?.model_name || leadDetail.lead_vehicle_detail?.other_model_name || ''
                                            } ${leadDetail.lead_vehicle_detail?.variant_name || leadDetail.lead_vehicle_detail?.other_variant_name || ''}`}
                                    >{`${leadDetail.lead_vehicle_detail?.make_name || leadDetail.lead_vehicle_detail?.other_make_name || ''} ${leadDetail.lead_vehicle_detail?.model_name || leadDetail.lead_vehicle_detail?.other_model_name || ''
                                        } ${leadDetail.lead_vehicle_detail?.variant_name || leadDetail.lead_vehicle_detail?.other_variant_name || ''}`}</span>
                                    {leadDetail.lead_vehicle_detail?.make_name ||
                                        leadDetail.lead_vehicle_detail?.model_name ||
                                        leadDetail.lead_vehicle_detail?.variant_name ||
                                        leadDetail.lead_vehicle_detail?.other_make_name ||
                                        leadDetail.lead_vehicle_detail?.other_model_name ||
                                        leadDetail.lead_vehicle_detail?.other_variant_name ? (
                                        <span
                                            onClick={() => {
                                                toggleTip1(true);
                                                toggleTip(false);
                                                toggleTip2(false);
                                                toggleTip3(false);
                                                toggleTip4(false);
                                                toggleTip5(false);
                                            }}
                                            title=""
                                            className="tooltio-ic"
                                        >
                                            <i className="ic-info-icon1"></i>
                                        </span>
                                    ) : null}
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip1(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Make</span>
                                                            <label>{leadDetail.lead_vehicle_detail?.make_name || leadDetail.lead_vehicle_detail?.other_make_name || ""}</label>
                                                        </li>
                                                        <li>
                                                            <span>Model</span>
                                                            <label>{leadDetail.lead_vehicle_detail?.model_name || leadDetail.lead_vehicle_detail?.other_model_name || ""}</label>
                                                        </li>
                                                        <li>
                                                            <span>variant</span>
                                                            <label>
                                                                {leadDetail.lead_vehicle_detail?.variant_name || leadDetail.lead_vehicle_detail?.other_variant_name || ""}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>Manufacturing year</span>
                                                            <label>{leadDetail.lead_vehicle_detail?.make_year}</label>
                                                        </li>
                                                        <li>
                                                            <span>Plate Number</span>
                                                            <label>
                                                                {leadDetail.lead_vehicle_detail?.plate_number}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen1}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Service Type"}>Service Type</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span
                                        className="truncate"
                                        title={`${leadDetail.service_type?.service_type || ''}`}
                                    >{`${leadDetail.service_type?.service_type || ''}`}</span>
                                    {leadDetail.service_type?.service_type ? (
                                        <span
                                            onClick={() => {
                                                toggleTip1(false);
                                                toggleTip(true);
                                                toggleTip2(false);
                                                toggleTip3(false);
                                                toggleTip4(false);
                                                toggleTip5(false);
                                            }}
                                            title=""
                                            className="tooltio-ic"
                                        >
                                            <i className="ic-info-icon1"></i>
                                        </span>
                                    ) : null}
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Service Type</span>
                                                            <label>{leadDetail.service_type?.service_type}</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Status"}>Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li
                                    title={
                                        leadDetail?.lead_status?.name +
                                        (leadDetail?.lead_sub_status?.id
                                            ? ' (' + leadDetail?.lead_sub_status?.sub_status_name + ') '
                                            : "")
                                    }
                                >
                                    <span class="truncate">
                                        {' '}
                                        {leadDetail?.lead_status?.name}
                                        {leadDetail?.lead_sub_status?.id
                                            ? ' (' + leadDetail?.lead_sub_status?.sub_status_name + ') '
                                            : ""}{' '}
                                    </span>
                                    {leadDetail?.lead_status?.name ? (
                                        <span
                                            onClick={() => {
                                                toggleTip1(false);
                                                toggleTip(false);
                                                toggleTip2(false);
                                                toggleTip3(false);
                                                toggleTip4(false);
                                                toggleTip5(false);
                                                toggleTip6(true);
                                            }}
                                            title=""
                                            className="tooltio-ic"
                                        >
                                            <i className="ic-info-icon1"></i>
                                        </span>
                                    ) : null}
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip6(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Status</span>
                                                            <label>{' '}
                                                                {leadDetail?.lead_status?.name}
                                                                {leadDetail?.lead_sub_status?.id
                                                                    ? ' (' + leadDetail?.lead_sub_status?.sub_status_name + ') '
                                                                    : ""}{' '}</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen6}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Doc Status"}>Doc Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li
                                    title={
                                        leadDetail?.doc_status === '0'
                                            ? 'Pending'
                                            : leadDetail?.doc_status === '1'
                                                ? 'Submitted'
                                                : leadDetail?.doc_status === '2'
                                                    ? 'Approved'
                                                    : ''
                                    }
                                >
                                    {leadDetail?.doc_status === '0'
                                        ? 'Pending'
                                        : leadDetail?.doc_status === '1'
                                            ? 'Submitted'
                                            : leadDetail?.doc_status === '2'
                                                ? 'Approved'
                                                : ''}
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <button
                            type="button"
                            className="btn-primary-accent history-btn"
                            onClick={() => showModalViewTimeLine(leadDetail.id)}
                        >
                            View History
                            <i className="ic-arrow_forward"></i>
                        </button>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Payment Received Status"}>Payment Received Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li
                                    title={leadDetail?.payment_status || ''}
                                >
                                    {leadDetail?.payment_status || ''}
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Payment Paid Status"}>Payment Paid Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li
                                    title={leadDetail?.payment_paid_status || ''}
                                >
                                    {leadDetail?.payment_paid_status || ''}
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Dealer Details"}>Dealer Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="truncate" title={leadDetail?.dealer_name || ''}>
                                        {leadDetail?.dealer_name || ''}
                                    </span>
                                    {leadDetail?.dealer_name ? (
                                        <span
                                            onClick={() => {
                                                toggleTip3(true);
                                                toggleTip(false);
                                                toggleTip1(false);
                                                toggleTip2(false);
                                                toggleTip4(false);
                                                toggleTip5(false);
                                            }}
                                            title=""
                                            className="tooltio-ic"
                                        >
                                            <i className="ic-info-icon1"></i>
                                        </span>
                                    ) : null}
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip3(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Dealer Name</span>
                                                            <label>{leadDetail?.dealer_name}</label>
                                                        </li>
                                                        <li>
                                                            <span>GCD Code</span>
                                                            <label>{leadDetail?.dealer_details?.gcd_code}</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen3}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Area Coverage"}>Area Coverage</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadDetail?.service_coverage?.name}>
                                    <span class="truncate"> {leadDetail?.service_coverage?.name} </span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"LTO Office Code"}>LTO Office Code</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span
                                        className="truncate"
                                        title={leadDetail?.lead_detail?.outbound_lto_office?.lt_office_name}
                                    >
                                        {leadDetail?.lead_detail?.outbound_lto_office?.lt_office_name}
                                    </span>
                                    {leadDetail?.lead_detail?.outbound_lto_office?.lt_office_name && (
                                        <span
                                            onClick={() => {
                                                toggleTip4(true);
                                                toggleTip(false);
                                                toggleTip1(false);
                                                toggleTip2(false);
                                                toggleTip3(false);
                                                toggleTip5(false);
                                            }}
                                            title=""
                                            className="tooltio-ic"
                                        >
                                            <i className="ic-info-icon1"></i>
                                        </span>
                                    )}{' '}
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip4(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Outgoing LTO Office Code</span>
                                                            <label>
                                                                {leadDetail?.lead_detail?.outbound_lto_office
                                                                    ?.lt_office_name || ''}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>Incoming LTO Office Code</span>
                                                            <label>
                                                                {leadDetail?.lead_detail?.inbound_lto_office
                                                                    ?.lt_office_name || ''}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen4}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Agent Name"}>Agent Name</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadDetail?.lead_assigned_agent?.agent_name}>
                                    <span class="truncate"> {leadDetail?.lead_assigned_agent?.agent_name} </span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate">Agent Fee</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="truncate" title={leadDetail?.lead_detail?.standard_ttp || ''}>
                                        {leadDetail?.total_amount_paid_till_now ? '₱ ' + leadDetail?.total_amount_paid_till_now : ""}
                                    </span>
                                    {/* {leadDetail?.lead_detail?.standard_ttp ? (
                                        <span
                                            onClick={() => {
                                                toggleTip5(true);
                                                toggleTip3(false);
                                                toggleTip(false);
                                                toggleTip1(false);
                                                toggleTip2(false);
                                                toggleTip4(false);
                                            }}
                                            title=""
                                            className="tooltio-ic"
                                        >
                                            <i className="ic-info-icon1"></i>
                                        </span>
                                    ) : null} */}
                                    <Tooltip
                                        html={
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span
                                                        className="controlled-example_close-button"
                                                        onClick={() => toggleTip5(false)}
                                                    >
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Agent Fee</span>
                                                            <label>{leadDetail?.lead_detail?.agent_fee ? '₱ ' + leadDetail?.lead_detail?.agent_fee : ""}</label>
                                                        </li>
                                                        <li>
                                                            <span>New Request paid on date and amount</span>
                                                            <label>
                                                                {(leadDetail?.latest_request_paid_date
                                                                    ? dateFormat(
                                                                        leadDetail?.latest_request_paid_date,
                                                                        'dd mmm, yyyy',
                                                                    )
                                                                    : '') +
                                                                    (leadDetail?.latest_request_paid_amount
                                                                        ? ' & ₱ ' + leadDetail?.latest_request_paid_amount
                                                                        : '')}
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <span>Total Amount Paid to Agent till now</span>
                                                            <label>{leadDetail?.total_amount_paid_till_now ? '₱ ' + leadDetail?.total_amount_paid_till_now : ""}</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        open={tipOpen5}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    ></Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Standard TT Charges"}>Standard TT Charges</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="truncate" title={leadDetail?.total_amount_received_till_now || ''}>
                                        {leadDetail?.total_amount_received_till_now ? '₱ ' + leadDetail?.total_amount_received_till_now : ""}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading truncate" title={"Case Type"}>Case Type</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={LEAD_SOURCE_TAGGING.filter(data => data.value === leadDetail?.lead_source_tagging).map(v => v.label)[0]}>
                                    <span class="truncate"> {LEAD_SOURCE_TAGGING.filter(data => data.value === leadDetail?.lead_source_tagging).map(v => v.label)[0] || 'NA'} </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                    {leadDetail?.loan_id ? (
                        <>
                            <li className="maindetail-item">
                                <h3 className="subheading truncate" title={"UCF Loan ID"}>UCF Loan ID</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li>
                                            <span className="buyer-txt" title={leadDetail.loan_id}>
                                                {leadDetail?.loan_code}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading truncate" title={"UCF Status"}>UCF Status</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li>
                                            <span
                                                className="buyer-txt"
                                                title={leadDetail?.loan_status_sub_status?.status_info?.name}
                                            >
                                                {leadDetail?.loan_status_sub_status?.status_info?.name}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading truncate" title={"UCF Sub Status"}>UCF Sub Status</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li>
                                            <span
                                                className="buyer-txt"
                                                title={leadDetail?.loan_status_sub_status?.sub_status_info?.name}
                                            >
                                                {leadDetail?.loan_status_sub_status?.sub_status_info?.name}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : null}
                    <li className="maindetail-item">
                        {![6, 7].includes(leadDetail?.status_id) &&
                            !leadHistory?.find((history) => [6].includes(history.status_id)) ? (
                            accessConditionLost || isLeadAssignedLost ? (
                                <button className="rollback-btn" type="button" disabled={true}>
                                    Mark as Lost
                                </button>
                            ) : (
                                <button
                                    className="lost-btn"
                                    onClick={showModalMarkAsLost}
                                    disabled={accessConditionLost || isLeadAssignedLost}
                                >
                                    Mark as Lost
                                </button>
                            )
                        ) : (
                            <></>
                        )}
                        {leadDetail?.status_id === 7 &&
                            !leadHistory?.find((history) => [6].includes(history.status_id)) ? (
                            accessConditionReopen || isLeadAssignedReopen ? (
                                <button className="rollback-btn" type="button" disabled={true}>
                                    Re-open
                                </button>
                            ) : (
                                <button
                                    className="reopen-btn"
                                    onClick={showModalReopen}
                                    disabled={accessConditionReopen || isLeadAssignedReopen}
                                >
                                    Re-open
                                </button>
                            )
                        ) : (
                            <></>
                        )}
                    </li>
                </ul>
                <div className="view-timeline-popup image-timeline-popup">
                    {ViewTimeLine ? (
                        <div className="view-timeline-popup image-timeline-popup">
                            <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                                <div className="modal-header">
                                    <h2>Customer Timeline</h2>
                                </div>
                                <LeadHistory historyList={leadHistory} isLoading={false} t={t} />
                            </Modal>
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div className="mark-lost-popup">
                    <Modal show={MarkAsLost} handleClose={hideModalMarkAsLost}>
                        <div className="modal-header">
                            <h2>Mark as Lost</h2>
                        </div>
                        <div className="modal-body">
                            <fieldset className="single-select">
                                <div className="material">
                                    <Select
                                        options={subStatusList}
                                        placeholder="Sub Status"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        value={subStatusList?.filter(({ value }) => value === subStatus.sub_status)}
                                        onChange={handleChangeOption}
                                        aria-label="sub status"
                                    />
                                </div>
                            </fieldset>
                            <fieldset class="form-filed">
                                <div class="material">
                                    <textarea
                                        placeholder=" "
                                        class="form-input"
                                        rows="3"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        aria-label="comment"
                                    ></textarea>
                                    <label data-label="Comment" class="form-label"></label>
                                </div>
                            </fieldset>
                            <button className="btn-primary" onClick={submitMarkAsLost}>
                                Save
                            </button>
                        </div>
                    </Modal>

                    <Modal show={reopenModal} handleClose={hideModalReopen}>
                        <div className="modal-header">
                            <h2>Re-open</h2>
                        </div>
                        <div className="modal-body">
                            <fieldset class="form-filed">
                                <div class="material">
                                    <textarea
                                        placeholder=" "
                                        class="form-input"
                                        rows="3"
                                        value={remarkText}
                                        onChange={(e) => setRemarkText(e.target.value)}
                                        aria-label="remarks"
                                    ></textarea>
                                    <label data-label="Remarks" class="form-label"></label>
                                </div>
                            </fieldset>
                            <button className="btn-primary" onClick={submitReopen}>
                                Save
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn" aria-label="handle close">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default LoanRibbenBox;
