import React, { useState } from "react";
import { REVIEW_PAGES } from "../../config/constants";


const PreviewRating = ({ lead_rating, page }) => {
    const ratingRange = [...Array(lead_rating?.out_of).keys()].map(i => i + 1);
    ;
    const [ratingArray] = useState(ratingRange);
    const [ratingDetail] = useState({ ...lead_rating });
    return (
        <React.Fragment>
            <div className="review-rating-outer">
                <h2>
                    {
                        (page === REVIEW_PAGES.ORCR_SEND_TO_DEALER &&
                            `Customer Experience`) || (page === REVIEW_PAGES.STATUS_TRACKING &&
                                `Your Experience`) || ''
                    }
                </h2>
                <div className="rating-start">
                    {ratingArray?.map((rate) => (
                        <i className={`${(rate <= ratingDetail?.rating && 'ic-star-filled') || 'ic-star-empty'}`}></i>
                    ))}
                </div>
                {ratingDetail?.rating <= 3 && (
                    <fieldset class="form-filed">
                        <div class="material">
                            <textarea class="form-input" placeholder=" " rows="3"
                                value={ratingDetail?.remark || ''} aria-label="remark"></textarea>
                            <label data-label="Comments" class="form-label">
                            </label>
                        </div>
                    </fieldset>
                )
                }
            </div>
        </React.Fragment>
    );
};

export default PreviewRating;