const masterDataReducer = (state = '', action) => {
    switch (action.type) {
        case 'GET_MASTER_DATA':
            // console.log(action.payload)
            return {
                ...state,
                data: action.payload,
            };
        case 'GET_MASTER_DATA_MMV':
            return {
                ...state,
                masterdatammv: action.payload,
            };
        case 'GET_VEHICLE_USAGE':
            return {
                ...state,
                masterdataVehicleUsage: action.payload,
            };
        case 'GET_STATUS_LIST':
            return {
                ...state,
                statusList: action.payload,
            };
        case 'GET_AGENT_LIST':
            return {
                ...state,
                agentList: action.payload,
            };
        case 'GET_DEALER_LIST':
            return {
                ...state,
                dealerList: action.payload,
            };
        case 'GET_SALES_OFFICER':
            return {
                ...state,
                soList: action.payload,
            };
        case 'OPS_EXECUTIVE_LIST':
            return {
                ...state,
                opsExecutiveList: action.payload,
            };
        case 'GET_BANKS':
            return {
                ...state,
                bankList: action.payload,
            };
        case 'GET_FINANCIER':
            return {
                ...state,
                financierList: action.payload,
            };
        default:
            return state;
    }
};

export default masterDataReducer;
