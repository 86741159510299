import { useState } from "react";

const OfficePriceTypeView = ({ lto_office_agent }) => {
    lto_office_agent = lto_office_agent?.filter(lto_office => lto_office?.lt_office_id === 0);
    const maxDisplayedOffices = 1;
    const [showAllOffices, setShowAllOffices] = useState(false);
    const handleViewMore = () => {
        setShowAllOffices(!showAllOffices);
    };

    const renderOffices = (offices) => {
        return offices.map(({ lt_office_id, lto_office, lto_agent_service, lto_office_region }, index) => (
            <tr className="show-border-bottom">
                <td>
                    {lt_office_id === 0 ? lto_office_region?.region_name : lto_office?.lt_office_name || '-'}
                    {(lto_office_agent.length > maxDisplayedOffices && (index === offices.length - 1) && (
                        <><br />
                            <button className="btn-line" onClick={handleViewMore}>
                                {showAllOffices ? '- Show Less' : `+ ${lto_office_agent.length - maxDisplayedOffices} more`}
                            </button>
                        </>
                    )) || null}
                </td>
                <td>
                    <table className='sub-table-sub-data'>
                        {lto_agent_service.map(({ service_details, agent_fee }, index) => (<>
                            <tr>
                                <td>{service_details?.service_type || '-'}</td>
                                <td>{`₱ ${+agent_fee || '0'}`}</td>
                            </tr>
                        </>))}
                    </table>
                </td>
            </tr>
        ));
    };

    const displayedOffices = renderOffices(
        showAllOffices ? lto_office_agent : lto_office_agent.slice(0, maxDisplayedOffices)
    );

    return (
        <>
            <table className='sub-table'>
                {displayedOffices}
            </table>
            {/* {lto_office_agent.length > maxDisplayedOffices && (
                <button className="btn-line" onClick={handleViewMore}>
                    {showAllOffices ? '- Show Less' : `+ ${lto_office_agent.length - maxDisplayedOffices} more`}
                </button>
            )} */}
        </>
    );
};


export default OfficePriceTypeView;