import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MultiSelect from '../elements/MultiSelect';

const AgentListFilter = ({ handleSearch }) => {
    const [filterData, setFilterData] = useState({});
    const { service_type, lto_office_type } = useSelector(({ masterdata }) => ({
        service_type: masterdata?.data?.service_type,
        lto_office_type: masterdata?.data?.lto_office_type,

    }));

    const handleChange = async (name, value) => {
        if (['service_id', 'lt_office_id'].includes(name)) {
            setFilterData((prev) => ({ ...prev, [name]: value.map((obj) => obj["id"]) }));
        }
        else if (['search_text'].includes(name)) {
            setFilterData((prev) => {
                return {
                    ...prev,
                    search_text: '',
                    [name]: value.target.value,
                };
            });
        }
        else {
            setFilterData((prev) => {
                return {
                    ...prev,
                    [name]: value.value,
                };
            });
        }
    };
    const searchAgent = (e) => {
        handleSearch(filterData);
    };

    const resetSearchAgent = (e) => {
        document.getElementById('more-filter-dropdown').classList.remove('show');
        setFilterData({});
        handleSearch({});
    };

    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <div className="filter-filed-form">
                    <fieldset className="search-bx finance-search">
                        <div className="material">
                            <input
                                id=""
                                type="text"
                                aria-label="search text"
                                name="search_text"
                                value={filterData?.search_text || ''}
                                onChange={(e) => handleChange('search_text', e)}
                                className="form-input"
                                title={'SEARCH_TEXT'}
                                placeholder="Search By Agent Name or Mobile No."
                            />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>
                </div>
                <div className="filter-filed-form">
                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                options={lto_office_type || []}
                                placeholder={'LTO Office Code'}
                                name={'lt_office_id'}
                                optionLabel="label"
                                optionValue="id"
                                values={filterData?.lt_office_id || []}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </div>
                <div className="filter-filed-form">
                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                options={service_type || []}
                                placeholder={'Select Service Type'}
                                name={'service_id'}
                                optionLabel="label"
                                optionValue="id"
                                values={filterData?.service_id || []}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </div>
                <div className="filter-filed-form">
                    <div className="more-filter">
                        <div className="filter-option">
                            <Dropdown id="more-filter-dropdown">
                                <div className="text-btn d-flex">
                                    <div className="btn-submit-reset top-btn-none">
                                        <button onClick={searchAgent} className="btn-primary">
                                            Search
                                        </button>
                                        <button onClick={resetSearchAgent} className="btn-reset">
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AgentListFilter;
