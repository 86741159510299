import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { GET_SERVICES_PRICE, UPDATE_SERVICES_PRICE } from '../../services/services.ggl';

const EditServicesPrice = ({ hideModalEditServicesPrice }) => {
    const [allowSubmit, setAllowSubmit] = useState(false);
    const client = useApolloClient();

    const { user_information } = useSelector(({ masterdata, user }) => {
        return {
            user_information: user.user_information,
        };
    });
    const UserInfo = JSON.parse(user_information);
    const [serviceType, setServiceType] = useState([]);

    useEffect(() => {
        get_services_price();
    }, []);
    const handleChange = (index, e) => {
        if (e.target.value < 0 || !e.target.value) {
            e.target.value = 0;
        }
        serviceType[index].standard_price = +e.target.value;
        setServiceType([...serviceType]);
    };

    const get_services_price = () => {
        executeGraphQLMutation(GET_SERVICES_PRICE, {}, client)
            .then((resp) => {
                if (resp?.data?.get_services_price) {
                    setServiceType([...resp?.data?.get_services_price]);
                    toast.success(resp?.data?.get_services_price);
                } else {
                    toast.error('Data might be not updated');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleSubmit = () => {
        const user_id = UserInfo?.id || 0,
            role_id = UserInfo?.role_id?.[0] || 0;
        let services_price_input = serviceType.map(({ id, standard_price }) => ({
            id,
            standard_price: standard_price,
        })),
            variables = { user_id, role_id, api_called_by: 'web', services_price_input };

        executeGraphQLMutation(UPDATE_SERVICES_PRICE, variables, client)
            .then((resp) => {
                if (resp?.data?.update_services_price) {
                    toast.success(resp?.data?.update_services_price?.message);
                    setTimeout(() => {
                        hideModalEditServicesPrice();
                    }, 2000);
                } else {
                    toast.error('Something went wrong');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    return (
        <div className="">

            <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => setAllowSubmit(true)}
                onInvalid={() => setAllowSubmit(false)}
                onValidSubmit={handleSubmit}
                aria-label="lead form field basic"
            > <ul className='select-service-list p-md-t'>
                    {serviceType?.map((data, index) => (
                        <li className="form-group">
                            <label htmlFor="carTransfer" className='select-service-heading '>{data?.label}</label>
                            <fieldset class="enter-agent-fee">
                                <div class="material">

                                    <input
                                        className="form-input"
                                        type="number"
                                        id={data?.label?.split(' ').join('-').toLowerCase()}
                                        name={data?.label?.split(' ').join('-').toLowerCase()}
                                        value={serviceType?.[index]?.standard_price}
                                        onChange={(e) => handleChange(index, e)}
                                        placeholder="Enter Price"
                                        min={0}
                                    />
                                </div>
                            </fieldset>
                        </li>
                    ))}
                </ul>

                <div className="btn-save-service-type">
                    {(!allowSubmit && <span className="span-disabled">Submit</span>) || (
                        <button type="submit" className="btn-primary">
                            Submit
                        </button>
                    )}
                </div>
            </Formsy>

        </div>
    );
};

export default EditServicesPrice;
