import { gql } from '@apollo/client';

export const GET_AGENT_LEAD_DETAIL = gql`
    query Get_agent_lead_detail($api_called_by: API_CALL_BY!, $agent_lead_detail_input: AgentLeadDetail!) {
        get_agent_lead_detail(agent_lead_detail_input: $agent_lead_detail_input, api_called_by: $api_called_by) {
            id
            customer {
                name
            }
            lead_detail {
                agent_fee
                agent_tat
                inbound_lto_office {
                    lt_office_name
                }
            }
            lead_vehicle_detail {
                make_id
                model_id
                variant_id
                make_name
                model_name
                variant_name
                other_make_name
                other_model_name
                other_variant_name
                plate_number
            }
            service_type {
                service_type
                standard_price
            }
            transfer_type {
                id
                transfer_type
            }
            lead_docs {
                doc_path
                doc_id
                doc_detail {
                    id
                    name
                    parent_id
                    doc_detail {
                        id
                        name
                    }
                }
            }
        }
    }
`;
export const UPDATE_AGENT_ACTION = gql`
    mutation Update_agent_action($api_called_by: API_CALL_BY!, $agent_action_input: AgentActionInput!) {
        update_agent_action(agent_action_input: $agent_action_input, api_called_by: $api_called_by) {
            message
        }
    }
`;
