import { gql } from '@apollo/client';
export const DASHBOARD_QUERY = (user_id) => {
    return gql`
        {
            loan_dashboard_count(api_called_by: web, user_id: ${user_id}) {
                total_lead_count
                loan_summary {
                    name
                    lead_count
                    color_code
                    status_icon
                    filter_params {
                        status_id
                        doc_status
                        action_type
                        payment_status
                    }
                    card_styles {
                        icon_className
                        max_icon_paths
                    }
                }
            }
        }
    `;
};

export const DASHBOARD_QUERY_ORCR = () => {
    return gql`
        {
            orcr_dashboard(api_called_by: web) {
                name
                lead_count
                color_code
                status_icon
                filter_params{
                    status_id
                }
                card_styles{
                   icon_className
                   max_icon_paths
                }
            }
        }
    `;
};

export const DASHBOARD_UCF_QUERY_ORCR = () => {
    return gql`
        {
            ucf_orcr_dashboard(api_called_by: web) {
                name
                lead_count
                color_code
                status_icon
                filter_params{
                    status_id
                }
                card_styles{
                   icon_className
                   max_icon_paths
                }
            }
        }
    `;
};
