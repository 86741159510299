import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { LEAD_STATUS } from '../../../src/config/constants';

const LeadDetailTabs = (props) => {
    const leadDetail = useSelector((state) => state.lead.leadDetail);
    const tab_status = leadDetail?.tab_status || {};
    const docCategoryList = props.docCategoryList || [];
    const handleMarkAsFreezed = () => {
        let currentStatus = leadDetail?.status_id;

        return [LEAD_STATUS.LOST].includes(currentStatus);
        // return [7].includes(currentStatus) || !(paymentProofGenerated?.length && ['1', '2'].includes(currentDocStatus)) ? true : false;
    };

    const contextList = {
        docList: docCategoryList.get_document_list || [],
        markAsFreezed: handleMarkAsFreezed(),
        getLeadHistory: props.getLeadHistory
    };
    const params = useParams();
    const lead_id = params?.lead_id || '';

    if (leadDetail) {
        return (
            <div className="lead-detail-tabs">
                <div className="detail-form-sec">
                    <div className="menu-tab-left-panel">
                        <div className="dealer-tab-left-panel nav">
                            <ul>
                                <li>
                                    <NavLink
                                        aria-label="basic details"
                                        to={`/lead-detail/customer-details/basic-details/${lead_id}`}
                                        className={
                                            (tab_status?.customer_details?.is_completed && 'completed') ||
                                            (tab_status?.customer_details?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        Customer Details
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        aria-label="vehicle details"
                                        to={`/lead-detail/customer-details/vehicle-details/${lead_id}`}
                                        className={
                                            (tab_status?.vehicle_details?.is_completed && 'completed') ||
                                            (tab_status?.vehicle_details?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        Vehicle Details
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        aria-label="Send To Agent"
                                        to={`/lead-detail/sent-to-agent/${lead_id}`}
                                        className={
                                            (tab_status?.send_to_agent?.is_completed && 'completed') ||
                                            (tab_status?.send_to_agent?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        Send To Agent
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        aria-label="status details"
                                        to={`/lead-detail/status-details/${lead_id}`}
                                        className={
                                            (tab_status?.status_details?.is_completed && 'completed') ||
                                            (tab_status?.status_details?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        Status Details
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        aria-label="status details"
                                        to={`/lead-detail/payment-details/${lead_id}`}
                                        className={
                                            (tab_status?.payment_details?.is_completed && 'completed') ||
                                            (tab_status?.payment_details?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        Payment Details
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        aria-label="New Orcr"
                                        to={`/lead-detail/new-orcr/${lead_id}`}
                                        className={
                                            (tab_status?.new_orcr?.is_completed && 'completed') ||
                                            (tab_status?.new_orcr?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        New Orcr
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        aria-label="basic details"
                                        to={`/lead-detail/orcr-sent-to-dealer/${lead_id}`}
                                        className={
                                            (tab_status?.orcr_sent_to_dealer?.is_completed && 'completed') ||
                                            (tab_status?.orcr_sent_to_dealer?.is_active && 'active') ||
                                            'pending'
                                        }
                                    >
                                        <span class="img-type"></span>
                                        ORCR Sent to Dealer
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Component from route will render here */}
                    <div className="route-files">
                        <Outlet context={contextList} />
                    </div>
                </div>
            </div>
        );
    }
};

export default LeadDetailTabs;
