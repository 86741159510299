import Formsy from 'formsy-react';
import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Nav, Tab } from 'react-bootstrap';
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../elements/FormsySelect';
import { ONBOARDING_DETAILS } from './formFields/agent-onboarding.json';

import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import ImageWithAlt from '../../common/ImageWithAlt';
import { ADD_AGENT } from '../../services/agentonboading.gql';
import GeneralService from '../../services/generalService';
import { EXTRACT_NUMBER_REGEX, getKeysDataIfExist, PAYMENT_METHOD_OPTIONS } from '../../config/constants';
import SelectRegionAndService from './SelectRegionAndService';
const AgentOnboarding = ({ getAgentList, hideModalAgentOnboard, getAgentsInput, paginationInput, agentId, agents }) => {
    const client = useApolloClient();

    const [activeKey, setActiveKey] = useState("first");
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [agentDetails, setAgentDetails] = useState({});

    const { agent_doc_list, region_lto_office, region_key_val, region_lto_office_type, lto_office_key_val, lto_office_type, user_information, service_type, bankList } = useSelector(({ masterdata, user }) => {
        return {
            agent_doc_list: masterdata?.data?.agent_doc_list?.map(({ id, name, min_upload, max_upload, parent_id }) => ({
                value: id, label: name,
                max_upload,
                min_upload,
                parent_id
            })) || [],
            region_lto_office: masterdata?.data?.region_lto_office || [],
            region_key_val: masterdata?.data?.region_lto_office?.reduce((result, region) => {
                result[region?.id] = region?.label;
                return result;
            }, {}) || [],
            region_lto_office_type: masterdata?.data?.region_lto_office?.reduce((result, region) => {
                result[region?.id] = region?.region_lto_office || [];
                return result;
            }, {}) || {},
            lto_office_key_val: masterdata?.data?.region_lto_office?.reduce((result, region) => {
                result = {
                    ...result,
                    ...region?.region_lto_office?.reduce((result1, office) => {
                        result1[office?.id] = office?.label;
                        return result1;
                    }, {})
                };
                return result;
            }, {}) || {},
            lto_office_type: masterdata?.data?.lto_office_type || [],
            service_type: masterdata?.data?.service_type || [],
            user_information: user.user_information,
            bankList: masterdata?.bankList?.map(({ id, name }) => ({ value: id, label: name })) || [],

        };
    });
    // const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    const ALL_OPTIONS = {
        agent_doc_list, region_lto_office, region_key_val, region_lto_office_type, lto_office_key_val, lto_office_type, service_type, bankList, PAYMENT_METHOD_OPTIONS
    };
    useEffect(() => {
        const agentDetails = agents?.find((agent) => agent?.id === agentId);
        if (agentDetails) {
            const agentDetail = agentDetails?.lto_office_agent.reduce((result, lto_office) => {
                if (!result?.region_id?.includes(lto_office?.region_id)) result.region_id.push(lto_office?.region_id);
                if (!result?.region_details?.[lto_office?.region_id]) result.region_details[lto_office?.region_id] = {
                    label: region_key_val[lto_office?.region_id],
                    lt_office_details: {},
                    lt_office_id: []
                };
                lto_office?.lt_office_id !== 0 && result.region_details[lto_office?.region_id].lt_office_id.push(lto_office?.lt_office_id);
                const agent_services = lto_office?.lto_agent_service?.reduce((service_result, service) => {
                    service_result[service?.service_id] = { agent_fee: +service?.agent_fee, agent_tat: +service?.agent_tat };
                    return service_result;
                }, {});
                result.region_details[lto_office?.region_id].lt_office_details[lto_office?.lt_office_id || "region_group"] = { label: lto_office?.lto_office?.lt_office_name || "", ...agent_services };
                return result;
            }, { region_details: {}, region_id: [] }) || {};


            const docInfo = agent_doc_list?.filter((doc) => +doc.value === +agentDetails?.agent_docs?.[0]?.doc_id)?.[0] || {};
            setAgentDetails({
                ...agentDetails,
                agent_id: agentDetails?.id,
                ...agentDetail,
                doc_id: docInfo?.value,
                max_upload: docInfo?.max_upload,
                min_upload: docInfo?.min_upload,
                parent_id: docInfo?.parent_id,
                agent_docs: agentDetails?.agent_docs?.map((doc) => { return { doc_path: doc?.doc_path, signed_doc_path: doc?.signed_doc_path, name: "" }; })
            });
        } else {
            setAgentDetails({});
        }
    }, [agentId]);
    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            if (['bank_name_id'].includes(field_name)) {
                setAgentDetails((currentValue) => ({
                    ...currentValue,
                    bank_name_id: data?.value
                }));
            }
            else if (['doc_id'].includes(field_name)) {
                setAgentDetails((currentValue) => ({
                    ...currentValue,
                    doc_id: data?.value,
                    parent_id: data?.parent_id,
                    min_upload: data?.min_upload,
                    max_upload: data?.max_upload,
                }));
            }
            else if (["payment_method"].includes(field_name)) {
                let agentDetail = agentDetails;
                if (data?.value === PAYMENT_METHOD_OPTIONS?.[1]?.value) {
                    for (const key of ["bank_name_id", "account_number", "branch_name", "beneficiary_name"]) {
                        delete agentDetail?.[key];
                    }
                }
                agentDetail[field_name] = data?.value;
                setAgentDetails({ ...agentDetail });
            }
        }
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value !== undefined && field_name) {
            setAgentDetails((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };

    const handleInputChange = (event) => {
        if (event.target.id) {
            if (event.target.id === "account_number") {
                event.target.value = event.target.value.replace(EXTRACT_NUMBER_REGEX, "");
            }
            setAgentDetails((currentValue) => ({
                ...currentValue,
                [event.target.id]: event.target.value,
            }));
        }
    };

    const addAgent = () => {
        const variables = {
            add_agent_input: {
                ...((agentDetails?.agent_id && { agent_id: agentDetails?.agent_id }) || {}),
                ...getKeysDataIfExist(agentDetails, [
                    "agent_name",
                    "agent_mobile",
                    "agent_email",
                    "payment_method",
                    "bank_name_id",
                    "account_number",
                    "branch_name",
                    "beneficiary_name"
                ]),
                agent_docs: agentDetails?.agent_docs?.map((doc) => {
                    return {
                        doc_id: agentDetails?.doc_id,
                        parent_doc_id: agentDetails?.parent_doc_id,
                        doc_path: doc?.doc_path,
                        created_by: UserInfo?.id || 0,
                    };
                })

            },
            api_called_by: "web",
            user_id: UserInfo?.id,
            role_id: UserInfo?.role_id?.[0] || 0
        };

        executeGraphQLMutation(ADD_AGENT, variables, client)
            .then((resp) => {
                if (resp?.data?.add_agent) {
                    toast.success(resp?.data?.add_agent?.message);
                    setAgentDetails((prev) => ({ ...prev, agent_id: resp?.data?.add_agent.agent_id }));
                    setActiveKey("second");
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleChangeImage = (event, is_resubmit = false) => {
        let files = Array.from(event?.target?.files);
        if (agentDetails?.max_upload < (agentDetails?.agent_docs?.length + files.length)) {
            toast.error(`Max ${agentDetails?.max_upload} can be uploaded!`);
            return;
        }
        if (!files?.length) {
            return;
        }
        var formData = new FormData();
        formData.append('upload_type', 'finance_docs');
        formData.append('lead_id', 'tt_agent_docs');
        files.forEach((file, index) => {
            formData.append('images', file);
        });
        GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }).then((res) => {
            const docs = res?.data?.data?.map((doc, index) => {
                return { doc_path: doc?.file_url, signed_doc_path: doc?.file_url, name: files[index]?.name };
            });
            if (!agentDetails?.agent_docs) agentDetails.agent_docs = [];
            agentDetails.agent_docs.push(...docs);
            setAgentDetails((prev) => ({ ...prev }));
        });
    };


    const handleServicePriceChange = async (region, officeCode, service_type_id, e, field) => {
        const new_fields = { ...agentDetails?.region_details?.[region]?.lt_office_details };
        if (e.target.value) {
            if (!new_fields[officeCode]) new_fields[officeCode] = {};
            if (!new_fields[officeCode][service_type_id]) new_fields[officeCode][service_type_id] = {};
            new_fields[officeCode][service_type_id][field] = Math.abs(e.target.value);
        }
        else {
            delete new_fields[officeCode][service_type_id][field];
        }
        agentDetails.region_details[region].lt_office_details = new_fields;
        setAgentDetails((prev) => ({ ...prev, ...agentDetails }));
    };
    const handleRegionChange = async (name, value) => {
        let new_fields = {};
        let needToCallLtoSelect = [];
        new_fields.region_id = value?.map((obj) => obj["id"]);
        new_fields.region_details = new_fields.region_id?.reduce((result, region_id, index) => {
            if (agentDetails?.region_details?.[region_id]) {
                result[region_id] = { ...agentDetails.region_details[region_id] };
            } else {
                result[region_id] = { label: value[index]?.label };
                needToCallLtoSelect = value?.find((obj) => obj?.id === region_id)?.region_lto_office || [];
            }
            return result;
        }, {});
        if (needToCallLtoSelect?.length) {
            handleLtoChange("", needToCallLtoSelect, { ...new_fields });
        } else {
            setAgentDetails((prev) => ({ ...prev, ...new_fields }));
        }
    };
    const handleLtoChange = async (name, value, details = false) => {
        let new_fields = {};
        new_fields = details || agentDetails;
        const region_id = value[0]?.region_id;
        new_fields.region_details[region_id].lt_office_id = value?.map((obj) => obj["id"]);
        new_fields.region_details[region_id].lt_office_details = new_fields?.region_details?.[region_id]?.lt_office_id?.reduce((result, office_id, index) => {
            if (agentDetails?.region_details?.[region_id]?.lt_office_details?.[office_id]) {
                result[office_id] = { ...agentDetails?.region_details?.[region_id]?.lt_office_details?.[office_id] };
            } else {
                result[office_id] = { label: ALL_OPTIONS?.lto_office_key_val?.[office_id] || "" };
            }
            return result;
        }, {});
        setAgentDetails((prev) => ({ ...prev, ...new_fields }));
    };

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
            <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                <div className="tab-list d-flex">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Basic Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second">Service Details</Nav.Link>
                    </Nav.Item>
                </div>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="first">
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={addAgent}
                        aria-label="lead form field basic"
                    >
                        <div className="row">
                            {ONBOARDING_DETAILS?.map((field) =>
                                ((field?.dependentOn && field?.dependentValue.includes(agentDetails[field?.dependentOn])) || !field?.dependentOn) && (
                                    ['text', 'pattern-format', 'number-format'].includes(field.type) ? (
                                        <fieldset class="form-filed col-md-6 p-lg-t">
                                            <FormsyInputField
                                                id={field?.id}
                                                name={field.name}
                                                type={field.type}
                                                value={agentDetails?.[field?.name] || ""}
                                                placeholder=" "
                                                label={field?.label}
                                                onChange={
                                                    ['pattern-format', 'number-format'].includes(field?.type)
                                                        ? (data) => handlePatternFormatChange(data, field?.name)
                                                        : handleInputChange
                                                }
                                                format={field?.ApplyNumberformat ? field?.format : ''}
                                                validations={field?.validations}
                                                validationError={agentDetails?.[field?.name] ? field?.validationError : ''}
                                                required={field?.required}
                                                showAsterisk={field?.showAsterisk}
                                            />
                                        </fieldset>
                                    ) : field.type === 'dropdown' ? (
                                        <fieldset class={`single-select ${(field?.isFullWidth && "col-md-12") || "col-md-6"} p-lg-t`}>
                                            <FormsySelect
                                                name={field?.name}
                                                id={field?.id}
                                                inputProps={{
                                                    options: ALL_OPTIONS?.[field?.optionsKey] || [],
                                                    placeholder: field?.label,
                                                    className: 'react-select',
                                                    classNamePrefix: 'react-select',
                                                    value:
                                                        ALL_OPTIONS?.[field?.optionsKey]?.filter(
                                                            ({ value }) => value === agentDetails[field?.name],
                                                        ),
                                                }}
                                                required={field?.required}
                                                showAsterisk={field?.showAsterisk}
                                                value={agentDetails?.[field?.name] || ""}
                                                onChange={(data) => handleSelectChange(data, field?.name)}
                                            />
                                        </fieldset>
                                    ) : null)
                            )}
                            {(agentDetails?.doc_id && agentDetails?.agent_docs?.length && agentDetails?.agent_docs?.map(({ name, signed_doc_path }) => (
                                <div className="col-md-4 p-lg-t">
                                    <div className="img-bx-select-opt upload-tag-photo">
                                        <ImageWithAlt src={signed_doc_path} alt={name} />
                                    </div>
                                    <div class="custom-control custom-checkbox text-center">
                                        <label class="">{name}</label>
                                    </div>
                                </div >
                            )))
                                || null}
                            {
                                (agentDetails?.doc_id && (
                                    <div className="col-md-4 p-lg-t" >
                                        <div className="img-bx-select-opt upload-tag-photo">
                                            <div className="image-bx more-file-upload">
                                                <i className="ic-add"></i>
                                                <form
                                                    id="uploadForm"
                                                    method="post"
                                                    encType="multipart/form-data"
                                                >
                                                    <input
                                                        aria-label="upload form multiple"
                                                        type="file"
                                                        name="fileName[]"
                                                        multiple
                                                        id="fileupload"
                                                        onChange={(e) =>
                                                            handleChangeImage(
                                                                e
                                                            )
                                                        }
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                        <div class="custom-control custom-checkbox text-center">
                                            <label class="">Add Photo</label>
                                        </div>
                                    </div>
                                )) || null
                            }
                        </div >
                        <div className="btn-save-remarks p-lg-t">
                            {!allowSubmit ? (
                                <span className="span-disabled">Save & Next</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                        </div>
                    </Formsy >
                </Tab.Pane >
                <Tab.Pane eventKey="second">
                    {
                        <SelectRegionAndService
                            Accordion={Accordion}
                            AccordionItem={AccordionItem}
                            AccordionItemHeading={AccordionItemHeading}
                            AccordionItemButton={AccordionItemButton}
                            AccordionItemPanel={AccordionItemPanel}
                            agentDetails={agentDetails}
                            ALL_OPTIONS={ALL_OPTIONS}
                            handleRegionChange={handleRegionChange}
                            handleServicePriceChange={handleServicePriceChange}
                            handleLtoChange={handleLtoChange}
                            getAgentList={getAgentList}
                            hideModalAgentOnboard={hideModalAgentOnboard}
                            getAgentsInput={getAgentsInput}
                            paginationInput={paginationInput}
                        />
                    }
                </Tab.Pane>
            </Tab.Content >
        </Tab.Container >
    );
};
export default AgentOnboarding;
