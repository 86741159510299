import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import ImportFile from '../../../common/ImportFIle';
import {
    CHECK_ASSIGN_USER,
    DOC_IDS,
    IS_USER_ACCESS_PAGE,
    LEAD_STATUS,
    LEAD_SUB_STATUS,
    STATUS_CODES,
    UPDATE_LEAD_ORCR_ACTION
} from '../../../config/constants';
import GeneralService from '../../../services/generalService';
import { UPDATE_LEAD_ORCR } from '../../../services/leads.gql';
import { getLoanDetail } from '../../../store/action/allAction';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import Modal from '../../elements/Modal';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import DocumentGallery from '../DocumentGallery';

const NewOrcr = (props) => {
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isFieldsPresent, setIsFieldsPresent] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [newOrcrInput, setNewOrcrInput] = useState({});
    const [showremarkform, setShowremarkform] = useState(false);

    let { lead_id } = useParams();

    const { lead, remarksHistory, lead_docs, lead_status_history } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        lead_docs: lead?.leadDetail?.lead_docs || [],
        lead_status_history: lead?.leadDetail?.lead_status_history || [],
    }));

    useEffect(() => {
        const doc = lead_docs.filter((doc) => doc.doc_id === DOC_IDS.NEW_ORCR)[0] || {};
        doc.ext = doc?.doc_path?.split('?')[0].split('.').pop() || '';
        setNewOrcrInput(doc);
    }, [lead_docs]);

    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'new-orcr')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo?.id || 0;
    let role_id = UserInfo?.role_id?.[0] || 0;

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'new-orcr').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    useEffect(() => {
        if (lead?.state_id === STATUS_CODES.SENT_TO_AGENT) {
            setIsReadOnly(true);
        }
        lead?.leadDetail?.lead_detail?.new_orcr_generated_date &&
            setNewOrcrInput((prev) => ({
                ...prev,
                new_orcr_generated_date: lead?.leadDetail?.lead_detail?.new_orcr_generated_date,
            }));
    }, [lead]);

    const handleChange = (data, field_name) => {
        const newValues = {};

        if (field_name === 'new_orcr_generated_date' && data) {
            newValues[field_name] = data;
        }
        setNewOrcrInput((currentValue) => ({
            ...currentValue,
            ...newValues,
        }));
    };

    useEffect(() => {
        const fields = (newOrcrInput?.new_orcr_generated_date && newOrcrInput?.doc_path && true) || false;
        setIsFieldsPresent(fields);
    }, [newOrcrInput]);

    const handleSubmit = () => {
        let update_lead_orcr = {
            lead_id: lead?.leadDetail?.id,
            action: UPDATE_LEAD_ORCR_ACTION.NEW_ORCR,
            new_orcr: {
                new_orcr_generated_date: newOrcrInput?.new_orcr_generated_date,
                doc_path: newOrcrInput?.doc_path,
            },
        },
            variables = { user_id, role_id, api_called_by: 'web', update_lead_orcr };

        executeGraphQLMutation(UPDATE_LEAD_ORCR, variables, client)
            .then(async (resp) => {
                if (resp?.data?.update_lead_orcr) {
                    toast.success(resp?.data?.update_lead_orcr?.message);
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(getLoanDetail(lead?.leadDetail?.id, client));
                await getContext.getLeadHistory(lead?.leadDetail?.id);
                navigateToNext();
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const navigateToNext = () => {
        navigate(`/lead-detail/orcr-sent-to-dealer/${lead_id}`);
    };

    const isSubmitDisabled = useMemo(() => {
        return lead_status_history?.filter(
            (v) =>
                [LEAD_STATUS.ORCR_TRANSFER_COMPLETED].includes(v.status_id) &&
                [LEAD_SUB_STATUS.NEW_ORCR].includes(v.sub_status_id),
        )?.length
            ? true
            : !lead_status_history?.filter((v) => [LEAD_STATUS.IN_PROGRESS].includes(v.status_id))?.length
                ? true
                : false;
    }, [lead_status_history]);

    const showModalViewTimeLine = () => {
        setShowremarkform(true);
        setViewTimeLine(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const onChooseFile = async (selectedImage) => {
        if (!selectedImage) {
            toast.error('Please enter a name and upload an image.');
            return;
        }
        const file = selectedImage?.target?.files?.[0];
        const formData = new FormData();
        formData.append('upload_type', 'finance_docs');
        formData.append('lead_id', lead?.leadDetail?.id);
        formData.append('images', file);

        try {
            const publicUrl = await GeneralService.uploadFilesToS3(formData, {
                'Content-Type': 'multipart/form-data',
            });
            var doc_path = publicUrl.data.data[0].file_url || '';
            setNewOrcrInput((prev) => ({
                ...prev,
                doc_path,
                doc_name: file?.name
            }));
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const sectionInfo = { section: 'New ORCR', sub_section: '' };
    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>New RC</h2>
                    </div>
                </div>
                <Formsy
                    className="image-uploader-outer"
                    autoComplete="off"
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    onValidSubmit={handleSubmit}
                    aria-label="lead form field basic"
                >
                    {(isSubmitDisabled && (
                        <div className="carousel image-varification-slider">
                            <div className="carousel-slideimg pfd-img-height">
                                {['pdf', 'PDF'].includes(newOrcrInput?.ext) ? (
                                    <a
                                        className="pdf-img"
                                        href={newOrcrInput?.doc_path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                                    </a>
                                ) : (
                                    <img
                                        src={newOrcrInput?.doc_path}
                                        alt={newOrcrInput?.docname}
                                    />
                                )}
                            </div>
                        </div>
                    )) || (
                            <ImportFile name={newOrcrInput?.doc_name} onChooseFile={onChooseFile} field_text={'Upload new OCRC by dropping file here'} />
                        )}
                    <div className="row">
                        <fieldset className="form-filed col-md-12 p-lg-t">
                            <div className="material">
                                <div className="datepicker">
                                    <FormsyDatePicker
                                        selectedDate={
                                            (newOrcrInput['new_orcr_generated_date'] &&
                                                new Date(newOrcrInput['new_orcr_generated_date'])) ||
                                            ''
                                        }
                                        onDateSelect={(data) => handleChange(data, 'new_orcr_generated_date')}
                                        placeholder={'NEW ORCR Received Date'}
                                        name={'new_orcr_generated_date'}
                                        value={newOrcrInput && new Date(newOrcrInput['new_orcr_generated_date'])}
                                        minDate={new Date().setMonth(new Date().getMonth() - 2)}
                                        maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                        dateFormat="dd MMM, yyyy"
                                        validations={null}
                                        validationError={'Please select date'}
                                        required={true}
                                        clearDate={false}
                                        readOnly={isReadOnly || isSubmitDisabled}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className="btn-save-remarks">
                        {isSubmitDisabled ||
                            !allowSubmit ||
                            getContext.markAsFreezed ||
                            accessCondition ||
                            isLeadAssigned ||
                            !isFieldsPresent ? (
                            <span className="span-disabled">Save & Next</span>
                        ) : (
                            <button aria-label="save naxt" type="submit" className="btn-primary">
                                Save & Next
                            </button>
                        )}
                        <button
                            aria-label="remarks history"
                            type="button"
                            className="btn-line"
                            onClick={showModalViewTimeLine}
                        >
                            Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </Formsy>
            </div>
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
        </div>
    );
};
export default NewOrcr;
