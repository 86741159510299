import React, { useEffect, useState, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Tab } from 'react-bootstrap';
import ListGridView from '../leads/docs-upload/DocumentViewGallery';
import { useOutletContext } from 'react-router-dom';
import GeneralService from '../../services/generalService';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import Q from 'q';
import Loader from '../elements/Loader';

const DocumentGallery = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [docCategoryList, setDocCategoryList] = useState(props.documentCategoryList);
    const [documentList, setDocumentList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [seletedTabDocs, setSelectedTabDocs] = useState([]);
    const [selectedTab] = useState('first_submit');
    const [filterCategoryChildList, setFilterCategoryChildList] = useState([]);
    const [groupDocs, setGroupDocs] = useState({});
    const [defaultCategory, setDefaultCategory] = useState({ label: 'Two unique Govt IDs of Vendee', value: 4 });
    const [defaultSubCategory, setDefaultSubCategory] = useState({ label: 'Primary', value: 5 });
    const client = useApolloClient();
    const dispatch = useDispatch();

    const { lead, leadDetail, leadHistory } = useSelector(({ lead, user }) => ({
        lead,
        userInfo: user.user_information || {},
        leadDetail: lead.leadDetail,
        leadHistory: lead?.leadDetail?.lead_status_history,
    }));

    const context = useOutletContext();
    const markAsFreezed = context.markAsFreezed;

    const user_information = localStorage.getItem('user_information');
    let authUser = user_information && JSON.parse(user_information);

    const isSubmitDisabled = useMemo(() => {
        // return [1].includes(leadDetail?.status_id) ? true : false;
        return false;
    }, [leadDetail]);

    const [docGallery, setDocGallery] = useState({
        selectedCategory: { ...defaultCategory },
        selectedSubCategory: { ...defaultSubCategory },
        filterFiles: [],
        filterCategory: docCategoryList,
    });

    useEffect(() => {
        getDefaultCategory();
    }, [props]);

    const getDefaultCategory = () => {
        let docCategoryList = props.documentCategoryList.map(doc => { return { ...doc, label: doc.name, value: doc.id }; }) || [];
        docCategoryList = docCategoryList?.map(doc => {
            return {
                ...doc,
                child: doc?.child?.map(ch => {
                    return { ...ch, label: ch.name, value: ch.id };
                })
            };
        });
        let default_category = { label: docCategoryList?.[0]?.name, value: docCategoryList?.[0]?.id };
        let default_sub_category = docCategoryList?.[0]?.child?.length
            ? {
                label: docCategoryList?.[0]?.child?.[0]?.name,
                value: docCategoryList?.[0]?.child?.[0]?.id,
            }
            : {};
        setDefaultCategory(default_category);
        setDefaultSubCategory(default_sub_category);
        setDocCategoryList(docCategoryList);
        setDefaultImageCategory(default_category, default_sub_category);
    };

    useEffect(() => {
        setDocumentList(lead?.leadDetail?.lead_docs);
        let category = docCategoryList?.filter((f) => [0, null].includes(f.parent_id))
            .map((e) => ({ label: e.name, value: e.id }));
        let sub_category = docCategoryList?.filter((f) => f.id === defaultCategory.value)?.map((v) => v.child)[0] || [];
        sub_category = sub_category?.length && sub_category?.map((e) => ({ label: e.name, value: e.id }));
        let filterCategoryChildLists = [];
        docCategoryList &&
            docCategoryList.forEach((data) => {
                filterCategoryChildLists = [...filterCategoryChildLists, ...data.child];
            });
        setFilterCategoryChildList(filterCategoryChildLists);
        let selectedTabDocs = lead?.leadDetail?.lead_docs?.reduce((res, data) => {
            res.push(data);
            return res;
        }, []);

        let expandedDocArray = [];
        docCategoryList.forEach(item => {
            if (item.child && item.child.length > 0) {
                item.child.forEach(child => {
                    let category_docs = selectedTabDocs?.filter(doc => doc.doc_id === child.id && doc.parent_doc_id === child.parent_id);
                    expandedDocArray.push({
                        ...item,
                        ...child,
                        id: child.id,
                        label: `${item.label} (${child.label})`,
                        name: `${item.name} (${child.name})`,
                        parent_id: child.parent_id,
                        docs: category_docs || [],
                        child: []  // Make sure child array is empty in the expanded items
                    });
                });
            } else {
                item['docs'] = selectedTabDocs?.filter(doc => doc.doc_id === item.id);
                expandedDocArray.push(item);
            }
        });

        setGroupDocs(expandedDocArray);
        setCategoryList(category);
        setSubCategoryList(sub_category);
        setSelectedTabDocs(selectedTabDocs);
    }, [docCategoryList, lead]);

    const setDefaultImageCategory = (default_category, default_sub_category) => {
        /*  Set default image on load */
        let filtredFiles = leadDetail?.lead_docs?.reduce((res, data) => {
            if (data.doc_id === default_sub_category?.value || data.doc_id === default_category?.value) res.push(data);
            return res;
        }, []);
        setDocGallery({
            ...docGallery,
            filterFiles: filtredFiles,
            selectedCategory: { ...default_category },
            selectedSubCategory: { ...default_sub_category },
        });
    };

    const handleChangeImage = (event, doc_id, parent_doc_id, is_resubmit = false) => {
        let files = Array.from(event?.target?.files),
            awsRes = [],
            leadDocArr = [];
        let max_count = groupDocs?.filter(grp_docs => grp_docs.id === doc_id)?.[0]?.max_upload;
        let doc_count = leadDetail?.lead_docs?.filter(lead_doc => lead_doc.doc_id === doc_id)?.length + files?.length;
        if (doc_count > max_count) {
            toast.error('Cannot upload more Docs !');
        } else {
            if (files && files.length) {
                setIsLoading(true);
                var formData = new FormData();
                formData.append('upload_type', 'finance_docs');
                formData.append('lead_id', leadDetail.id);
                files.forEach((file, index) => {
                    formData.append('images', file);
                });
                awsRes.push(GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }));
            } else {
                awsRes.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
            }

            Q.allSettled(awsRes).then(async (uploadOutput) => {
                uploadOutput.forEach((res, index) => {
                    let response = res.value || [];
                    if (res.state === 'fulfilled') {
                        if (response.data.data.length) {
                            let resData = response.data.data;
                            resData.forEach((row, i) => {
                                let loanDetailsSaveDoc = {
                                    doc_path: row.file_url,
                                    parent_doc_id: parent_doc_id,
                                    doc_id: doc_id,
                                    doc_source: 'web',
                                };
                                leadDocArr.push(loanDetailsSaveDoc);
                            });
                        }
                    }
                });
                if (leadDocArr.length) {
                    let variables = {
                        saveDocInput: {
                            lead_id: leadDetail.id,
                            is_resubmit: is_resubmit,
                            lead_docs: leadDocArr,
                            api_called_by: 'web',
                            user_id: (authUser && authUser.id) || 0,
                            role_id: (authUser && authUser.role_id?.[0]) || 0,
                        },
                    };

                    let mutation = gql`
                    mutation SaveDoc($saveDocInput: SaveDocInput!) {
                        save_doc(saveDocInput: $saveDocInput) {
                            message
                        }
                    }
                `;

                    await executeGraphQLMutation(mutation, variables, client)
                        .then(async (result) => {
                            if (result && result.data && result.data.save_doc) {
                                await dispatch(getLoanDetail(leadDetail.id, client));
                                if (result.data.save_doc?.message) toast.success('Document uploaded successfully');
                                else toast.error("Can't Upload Doc !");
                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                            }
                        })
                        .catch((error) => {
                            setIsLoading(false);
                        });
                } else {
                    toast.error('Error in image upload!');
                    setIsLoading(false);
                    document.getElementById('uploadForm').reset();
                }
            });
        }
    };

    const handleChangeTagOption = (id, doc_id, parent_id) => {
        setIsLoading(true);
        let variables = {
            updateDocInput: {
                id: id,
                lead_id: leadDetail.id,
                parent_doc_id: parent_id,
                doc_id: doc_id,
                doc_source: 'web',
                status: '1',
                user_id: authUser && (authUser.id || 0)
            }
        };

        const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                    id
                    lead_id
                    doc_id
                    parent_doc_id
                }
            }`;

        executeGraphQLMutation(mutation, variables, client).then(result => {
            if (result && result.data) {
                toast.success("Success");
                setIsLoading(false);
                dispatch(getLoanDetail(leadDetail.id, client));
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            toast.error(error.toString().replace('ApolloError:', ''));
            setIsLoading(false);
        });
    };

    return (
        <div className="doument-gallery-tabs" key={selectedTab}>
            <Tab.Container defaultActiveKey={selectedTab}>
                <Nav variant="pills" className="flex-column">
                    {/* <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'first_submit'} eventKey="first_submit" disabled={false}>
                                First Submit
                            </Nav.Link>
                        </Nav.Item>
                    </div> */}
                </Nav>

                <Tab.Content>
                    <Tab.Pane active={selectedTab} activeTab={selectedTab} eventKey={selectedTab}>
                        <ListGridView
                            docGallery={docGallery}
                            categoryList={categoryList}
                            subCategoryList={subCategoryList}
                            docCategoryList={docCategoryList}
                            filterCategoryChildList={filterCategoryChildList}
                            documentList={documentList}
                            seletedTabDocs={seletedTabDocs}
                            authUser={authUser}
                            setDefaultImageCategory={setDefaultImageCategory}
                            setSubCategoryList={setSubCategoryList}
                            setDocGallery={setDocGallery}
                            leadDetail={leadDetail}
                            markAsFreezed={markAsFreezed}
                            groupDocs={groupDocs}
                            handleChangeImage={handleChangeImage}
                            leadStatusHistory={leadHistory}
                            dispatch={dispatch}
                            getLoanDetail={getLoanDetail}
                            handleChangeTagOption={handleChangeTagOption}
                            defaultCategory={defaultCategory}
                            defaultSubCategory={defaultSubCategory}
                            isSubmitDisabled={isSubmitDisabled}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            {isLoading ? <Loader /> : null}
        </div>
    );
};

export default memo(DocumentGallery);
