import { useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { Nav, Tab } from "react-bootstrap";

const RegionWiseServices = ({
    agentDetails,
    region,
    ALL_OPTIONS,
    handleServicePriceChange
}) => {
    const [activeKey, setActiveKey] = useState("first");
    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <div className="tab-list d-flex p-md-t">
                        <Nav.Item>
                            <Nav.Link eventKey="first">LTO Office Listing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Group Service Type & Price</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <Accordion allowZeroExpanded>
                            {agentDetails?.region_details?.[region]?.lt_office_id?.map(officeCode => (
                                <AccordionItem uuid={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} key={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                    <AccordionItemHeading id={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} aria-controls={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                        <AccordionItemButton id={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} aria-controls={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                            <h2 id={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} aria-controls={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                                {agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label}
                                            </h2></AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className='add-agent-list-heading-lto-office'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Select Service Type
                                                        </th>

                                                        <th>Agent TAT</th>
                                                        <th>Region TAT</th>


                                                        <th>Agent Fee</th>
                                                        <th>Region Fee</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ALL_OPTIONS["service_type"]?.map((data, index) => (
                                                        <tr>
                                                            <td>
                                                                <div className="custom-control custom-checkbox select-service-heading">
                                                                    <input
                                                                        id="pending"
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                    />
                                                                    <label className="custom-control-label">{data?.label}</label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <fieldset className="enter-agent-tat">
                                                                    <div class="material">
                                                                        <input
                                                                            className="form-input"
                                                                            type="number"
                                                                            id={data?.label?.split(' ').join('-').toLowerCase()}
                                                                            name={data?.label?.split(' ').join('-').toLowerCase()}
                                                                            value={agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.[data?.id]?.agent_tat}
                                                                            onChange={(e) => handleServicePriceChange(region, officeCode, data?.id, e, `agent_tat`)}
                                                                            placeholder="Enter TAT"
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                            </td>
                                                            <td> <fieldset className="group-tat">
                                                                <div class="material">
                                                                    <label>{agentDetails?.region_details?.[region]?.lt_office_details?.region_group?.[data?.id]?.agent_tat}</label>
                                                                </div>
                                                            </fieldset>
                                                            </td>

                                                            <td><fieldset className="enter-agent-fee">
                                                                <div class="material">
                                                                    <input
                                                                        className="form-input"
                                                                        type="number"
                                                                        id={data?.label?.split(' ').join('-').toLowerCase()}
                                                                        name={data?.label?.split(' ').join('-').toLowerCase()}
                                                                        value={agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.[data?.id]?.agent_fee}
                                                                        onChange={(e) => handleServicePriceChange(region, officeCode, data?.id, e, `agent_fee`)}
                                                                        placeholder="Enter Price"
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                            </td>
                                                            <td>
                                                                <fieldset className="group-fee">
                                                                    <div class="material">
                                                                        <label>{agentDetails?.region_details?.[region]?.lt_office_details?.region_group?.[data?.id]?.agent_fee}</label>
                                                                    </div>
                                                                </fieldset>
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>

                    </Tab.Pane >
                    <Tab.Pane eventKey="second">
                        <div className='add-agent-list-heading p-lg-t'>
                            <span className='heading-left'>Select Service Type</span>
                            <span className='heading-center'>Agent TAT</span>
                            <span className='heading-right'>Agent Fee</span>
                        </div>
                        <ul className="select-service-list p-md-t">
                            {ALL_OPTIONS?.["service_type"]?.map((data, index) => (
                                <li>

                                    <div className=" custom-control custom-checkbox select-service-heading">
                                        <input
                                            id="pending"
                                            type="checkbox"
                                            className="custom-control-input"
                                        />
                                        <label className="custom-control-label">{data?.label}</label>
                                    </div>
                                    <fieldset className="enter-agent-fee m-sm-r">
                                        <div class="material">
                                            <input
                                                className="form-input"
                                                type="number"
                                                id={data?.label?.split(' ').join('-').toLowerCase()}
                                                name={data?.label?.split(' ').join('-').toLowerCase()}
                                                value={agentDetails?.region_details?.[region]?.lt_office_details?.region_group?.[data?.id]?.agent_tat}
                                                onChange={(e) => handleServicePriceChange(region, "region_group", data?.id, e, `agent_tat`)}
                                                placeholder="Enter TAT"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="enter-agent-fee">
                                        <div class="material">
                                            <input
                                                className="form-input"
                                                type="number"
                                                id={data?.label?.split(' ').join('-').toLowerCase()}
                                                name={data?.label?.split(' ').join('-').toLowerCase()}
                                                value={agentDetails?.region_details?.[region]?.lt_office_details?.region_group?.[data?.id]?.agent_fee}
                                                onChange={(e) => handleServicePriceChange(region, "region_group", data?.id, e, `agent_fee`)}
                                                placeholder="Enter Price"
                                            />
                                        </div>
                                    </fieldset>
                                </li>
                            ))}
                        </ul>
                    </Tab.Pane>
                </Tab.Content >
            </Tab.Container >
        </div >
    );
};
export default RegionWiseServices;