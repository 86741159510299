import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from '../formFields/customerDetailsFormFields.json';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { SAVE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail, getFinancierList } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, CASE_TYPE_OPTIONS, CAR_UNDER_FINANCING_OPTIONS, FINANCING_FROM_OPTIONS, getKeysDataIfExist, fieldsNeedToDisable, SERVICE_TYPE_VALIDATION_IDS, DATE_FORMAT } from '../../../config/constants';
import { GET_LOCALITY_LIST, GET_REGION_CITY_LIST } from '../../../services/config.gql';
import * as moment from 'moment';
import { GET_DOCUMENT_CATEGORY_LIST } from '../../../services/leads.gql';

const FORM_FIELDS = FormFields['BASIC_DETAILS'];
const BasicDetailsForm = (props) => {
    const getContext = useOutletContext();
    const [basicDetails, setBasicDetails] = useState({});
    const [existingBasicDetails, setExistingBasicDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [city, setCityList] = useState([]);
    const [region, setRegionList] = useState([]);
    const [brangey, setBrangeyList] = useState([]);
    const [documentCategoryList, setDocumentCategoryList] = useState(getContext.docList || []);
    const { masterdata, lead, remarksHistory, leadHistory } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadHistory: lead?.leadDetail?.lead_status_history,
    }));

    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'customer-basic-details')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'customer-basic-details').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);
    useEffect(() => {
        docCategoryList(lead?.leadDetail?.service_type_id, +lead?.leadDetail?.service_type_id !== 3 ? lead?.leadDetail?.transfer_type_id : null);
    }, [lead?.leadDetail?.service_type_id, lead?.leadDetail?.transfer_type_id]);
    const docCategoryList = (service_type_id, transfer_type_id) => {
        ;
        executeGraphQLQuery(GET_DOCUMENT_CATEGORY_LIST(service_type_id, transfer_type_id), client)
            .then((resp) => {
                if (resp?.data?.get_document_list) {
                    setDocumentCategoryList([...resp?.data?.get_document_list]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    useEffect(() => {
        if (lead && lead.leadDetail && lead.leadDetail.customer) {
            let customerData = { ...lead.leadDetail.customer };
            customerData['service_type_id'] = lead.leadDetail?.service_type_id;
            customerData['mode_of_payment_id'] = lead.leadDetail?.lead_detail?.mode_of_payment_id;
            customerData['owner_name'] = lead.leadDetail?.lead_detail?.owner_name || "";
            customerData['owner_address'] = lead.leadDetail?.lead_detail?.owner_address || "";
            customerData = {
                ...customerData,
                ...getKeysDataIfExist(lead?.leadDetail, [
                    "transfer_type_id"
                ]),
                ...getKeysDataIfExist(lead?.leadDetail?.lead_detail, [
                    "case_type",
                    "car_under_financing",
                    "financing_from",
                    "financiers",
                    "other_financiers",
                    "payment_type_id"
                ])
            };
            setBasicDetails(customerData);
            setExistingBasicDetails(customerData);
        }
    }, [lead]);

    useEffect(() => {
        getCityList();
        dispatch(getFinancierList(client));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lead?.leadDetail?.customer?.city_id) getLocalityList(lead?.leadDetail.customer?.city_id);
    }, [lead?.leadDetail?.customer?.city_id]);

    useEffect(() => {
        let region_id = basicDetails['region_id'] || 0;
        if (ALL_OPTIONS?.city?.length && region_id) {
            let city_list = ALL_OPTIONS?.city?.filter((v) => v.state_id === String(region_id));
            setCityList(city_list);
            setBrangeyList([]);
        }
    }, [basicDetails['region_id']]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let city_id = basicDetails['city_id'] || 0;
        const fetchData = async () => {
            if (city_id) await getLocalityList(city_id);
        };
        fetchData();
    }, [basicDetails['city_id']]);

    let ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        const expirtDate = moment(moment(lead?.leadDetail?.lead_vehicle_detail?.or_expiry_date)?.utc()?.format(DATE_FORMAT));
        const currentDate = moment(moment(lead?.leadDetail?.created_date)?.utc()?.format(DATE_FORMAT));
        if (expirtDate.isBefore(currentDate)) {
            options['service_type'] = masterdata?.data?.service_type?.filter(ids => SERVICE_TYPE_VALIDATION_IDS?.OLDER?.includes(ids.id)) || [];
        }
        else if (!expirtDate.isBefore(currentDate)) {
            options['service_type'] = masterdata?.data?.service_type?.filter(ids => SERVICE_TYPE_VALIDATION_IDS?.GREATER?.includes(ids.id)) || [];
        }
        options['city'] = city || [];
        options['region'] = region || [];
        options['brangey'] = brangey || [];
        options['case_type_options'] = CASE_TYPE_OPTIONS || [];
        options['car_under_financing_options'] = CAR_UNDER_FINANCING_OPTIONS || [];
        options['financing_from_options'] = FINANCING_FROM_OPTIONS || [];
        options['financier_options'] = masterdata?.financierList?.map(financier => ({ value: financier?.id, label: financier?.financierlang?.financier_name })) || [];
        if (basicDetails?.service_type_id) {
            options['transfer_type'] = masterdata?.data?.transfer_type?.filter(service => masterdata?.data?.service_type?.find(service => service?.id === basicDetails?.service_type_id)?.transfer_type_id?.includes(service?.id)) || [];
        }
        return options;
    }, [masterdata, city, region, brangey, basicDetails?.service_type_id, lead?.leadDetail?.created_date, lead?.leadDetail?.lead_vehicle_detail?.or_expiry_date]);

    const getCityList = () => {
        executeGraphQLQuery(GET_REGION_CITY_LIST(), client)
            .then((resp) => {
                if (resp && resp.data && resp.data.get_region_city_list) {
                    let { region_list, city_list } = resp.data.get_region_city_list || [];
                    setRegionList(region_list);
                    setCityList(city_list);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getLocalityList = (city_id) => {
        executeGraphQLQuery(GET_LOCALITY_LIST(city_id), client)
            .then((resp) => {
                if (resp && resp.data && resp.data.get_locality_list) {
                    let { locality_list } = resp.data.get_locality_list || [];
                    setBrangeyList(locality_list);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleInputChange = (event) => {
        if (event.target.id) {
            setBasicDetails((currentValue) => ({
                ...currentValue,
                [event.target.id]: event.target.value,
            }));
        }
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value !== undefined && field_name) {
            setBasicDetails((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };

    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            const newFields = { [field_name]: data.value };
            if (['city_id', 'region_id'].includes(field_name)) {
                newFields.postal_code = '';
            }
            if (['car_under_financing'].includes(field_name)) {
                newFields.financing_from = null;
                newFields.financiers = null;
                newFields.other_financiers = null;
            }
            if (['service_type_id', 'transfer_type_id'].includes(field_name)) {
                newFields[field_name] = +data.value;
            }
            if (['service_type_id'].includes(field_name)) {
                newFields[`transfer_type_id`] = null;
            }
            setBasicDetails((currentValue) => ({
                ...currentValue,
                ...newFields
            }));
            if (field_name === 'brangey_id') {
                setBasicDetails((currentValue) => ({
                    ...currentValue,
                    'postal_code': data.zipcode,
                }));
            }
        }
    };

    const handleSubmit = () => {
        let lead_id = lead?.leadDetail?.id || 0;
        let reqBody = {
            lead_id: lead_id,
            standard_ttp: ALL_OPTIONS['service_type']?.filter(type => +type.id === +basicDetails?.service_type_id)?.[0]?.standard_pirce || 0,
            updated_by: user_id || 0,
            ...getKeysDataIfExist(basicDetails, [
                "name",
                "mobile",
                "email",
                "house_no",
                "street",
                "postal_code",
                ["service_type_id", "service_type_id", true],
                ["transfer_type_id", "transfer_type_id", true],
                ["region_id", "region_id", true],
                ["city_id", "city_id", true],
                ["brangey_id", "brangey_id", true],
                ["mode_of_payment_id", "mode_of_payment_id", true],
                "case_type",
                "car_under_financing",
                "financing_from",
                "financiers",
                "other_financiers",
                "owner_name",
                "owner_address",
                "payment_type_id"
            ])
        };

        if (reqBody.payment_type_id) reqBody.payment_type_id = +reqBody?.payment_type_id;

        const api_user_role = {
            api_called_by: "web", user_id: UserInfo?.id, role_id: UserInfo?.role_id?.[0] || 0
        };
        let mutation = SAVE_CUSTOMER_BASIC_DETAILS,
            variables = { basicDetailsInput: reqBody, ...api_user_role };

        executeGraphQLMutation(mutation, variables, client)
            .then((resp) => {
                let response = resp?.data?.save_customer_basic_details || null;
                if (response && !response.success) {
                    throw new Error(response.error || 'Something went wrong');
                }
                toast.success('Success');
                dispatch(getLoanDetail(lead_id, client));
                docCategoryList(reqBody?.service_type_id, +reqBody?.service_type_id !== 3 ? reqBody?.transfer_type_id : 0);
                navigateToNext();
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const navigateToNext = () => {
        navigate(`/lead-detail/customer-details/vehicle-details/${lead_id}`);
    };

    let BasicDetailsForm = useMemo(() => {
        return FORM_FIELDS.map((field) => {
            return field;
        });
    }, []);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
        // return leadHistory?.filter((v) => [4].includes(v.status_id))?.length || lead?.leadDetail?.doc_status === '0' ? true : false;
        return leadHistory?.filter((v) => [4].includes(v.status_id))?.length ? true : false;
    }, [leadHistory]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };
    const sectionInfo = { section: 'Customer details', sub_section: 'Basic details' };
    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>Customer Details</h2>
                    </div>
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleSubmit}
                        aria-label="lead form field basic"
                    >
                        <div className="row">
                            {BasicDetailsForm.map((field) =>
                                ((field?.dependentOn && field?.dependentValue.includes(basicDetails[field?.dependentOn])) || !field?.dependentOn) && (['text', 'pattern-format'].includes(field.type) ? (
                                    <fieldset class="form-filed col-md-6 ">
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={basicDetails && basicDetails[field.name]}
                                            placeholder=" "
                                            label={field.label}
                                            onChange={
                                                field.type === 'pattern-format'
                                                    ? (data) => handlePatternFormatChange(data, field.name)
                                                    : handleInputChange
                                            }
                                            format={field.ApplyNumberformat ? field.format : ''}
                                            validations={field.validations}
                                            validationError={basicDetails[field.name] ? field.validationError : ''}
                                            readOnly={lead?.leadDetail?.loan_id ? fieldsNeedToDisable(field?.name, existingBasicDetails[field?.name], 'with_ucf') : field.readOnly}
                                            required={field.required}
                                            showAsterisk={field.showAsterisk}
                                        />
                                    </fieldset>
                                ) : field.type === 'dropdown' ? (
                                    <fieldset class="single-select col-md-6">
                                        <FormsySelect
                                            name={field.name}
                                            id={field.id}
                                            inputProps={{
                                                options: ALL_OPTIONS?.[field?.optionsKey],
                                                placeholder: field.label,
                                                className: 'react-select',
                                                classNamePrefix: 'react-select',
                                                value: ALL_OPTIONS?.[field?.optionsKey]?.filter(
                                                    ({ value }) => value?.toString() === basicDetails?.[field?.name]?.toString(),
                                                ),
                                                isDisabled:
                                                    lead?.leadDetail?.loan_id ? fieldsNeedToDisable(field?.name, existingBasicDetails[field?.name], 'with_ucf') : field.readOnly,
                                            }}
                                            required={field.required}
                                            showAsterisk={field.showAsterisk}
                                            value={basicDetails && basicDetails[field.name]}
                                            onChange={(data) => handleSelectChange(data, field.name)}
                                        />
                                    </fieldset>
                                ) : null),
                            )}
                        </div>

                        <div className="btn-save-remarks">
                            {isSubmitDisabled ||
                                !allowSubmit ||
                                getContext.markAsFreezed ||
                                accessCondition ||
                                isLeadAssigned ? (
                                <span className="span-disabled">Save & Next</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                            <button
                                aria-label="remarks history"
                                type="button"
                                className="btn-line"
                                onClick={showModalViewTimeLine}
                            >
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </Formsy>
                </div>
            </div>
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
        </div>
    );
};
export default BasicDetailsForm;
